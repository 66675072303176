import {
  AmexIcon,
  ApplePayIcon,
  GooglePayIcon,
  MastercardIcon,
  PayPalIcon,
  VisaIcon
} from '@components/icons'

import { styled } from '@mui/material'

export const StyledVisaIcon = styled(VisaIcon)`
  width: 60px;
  height: 45px;
`

export const StyledMastercardIcon = styled(MastercardIcon)`
  width: 60px;
  height: 45px;
`

export const StyledAmexIcon = styled(AmexIcon)`
  width: 60px;
  height: 45px;
`

export const StyledPayPalIcon = styled(PayPalIcon)`
  width: 60px;
  height: 42px;
  border: 2px solid #d9d9d9;
  background-color: var(--Neutral1);
  border-radius: 4px;
`

export const StyledGooglePayIcon = styled(GooglePayIcon)`
  width: 60px;
  height: 42px;
  border: 2px solid #d9d9d9;
  background-color: var(--Neutral1);
  border-radius: 4px;
`

export const StyledApplePayIcon = styled(ApplePayIcon)`
  width: 60px;
  height: 42px;
  border: 2px solid #d9d9d9;
  background-color: var(--Neutral1);
  border-radius: 4px;
`
