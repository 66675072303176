import Image from 'next/image'

import { Fragment, useCallback } from 'react'

import Link from 'next/link'

import { Stack } from '@mui/material'

import { useQuery } from '@tanstack/react-query'

import { isWEB } from '@utils'

import { QueryClientKeysEnum } from '@enums'

import {
  CompetitionCategoriesService,
  CompetitionStatus,
  CompetitionsService
} from '@elitecompetitions/core-api'

import config from '@services/config'

import { getLocalAssetFromCDN } from '@helpers'
import { splitCompetitions } from '@helpers/competitions/splitCompetitions'

import {
  StyledAmexIcon,
  StyledMastercardIcon,
  StyledVisaIcon,
  StyledApplePayIcon,
  StyledGooglePayIcon,
  StyledPayPalIcon
} from './Footer.styled'

const months = [
  'null',
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

const categoriesParams = {
  take: -1,
  filterIsPrimary: true
}

const competitionsParams = {
  filterStatus: CompetitionStatus.ACTIVE,
  filterIsInvisible: false,
  take: -1
}

const Footer = () => {
  const { data: primaryCategories = [] } = useQuery({
    queryKey: [QueryClientKeysEnum.GET_CATEGORIES, categoriesParams],
    queryFn: () =>
      CompetitionCategoriesService.getCompetitionCategories(categoriesParams)
  })

  const { data: activeCompetitions = splitCompetitions([]) } = useQuery({
    queryKey: [QueryClientKeysEnum.GET_COMPETITIONS, competitionsParams],
    queryFn: () => CompetitionsService.getCompetitions(competitionsParams),
    select: ({ data }) => splitCompetitions(data)
  })

  const date = new Date()

  const renderActiveCompetitions = useCallback(() => {
    const competitions = [
      ...activeCompetitions.instaWins,
      ...activeCompetitions.featured
    ]

    const _fcomps = competitions.slice(0, 7)
    const total = _fcomps.length

    const compNames = _fcomps.map((item, idx) => {
      return (
        <Fragment key={`featured-footer-${item.id}`}>
          <Link prefetch={false} href={`/competitions/${item.slugUrl}`}>
            <a className="py-2 py-md-0 ptr active-comp">{item.name}</a>
          </Link>

          <span className="mx-2">{idx < total - 1 ? '|' : ''}</span>
        </Fragment>
      )
    })

    return <span>{compNames}</span>
  }, [activeCompetitions])

  const renderWinnersPodium = useCallback(() => {
    const date = new Date()

    return [0, 1, 2, 3, 4, 5, 6].map((item, idx) => {
      const year = date.getFullYear()
      const month = date.getMonth() + 1

      const dom = (
        <Fragment key={`winner-footer-${item}`}>
          <a className="py-2 py-md-0" href={`/winners-podium/${year}/${month}`}>
            {months[month]}, {year}
          </a>
          <span className="mx-2">{idx < 6 ? '|' : ''}</span>
        </Fragment>
      )

      date.setDate(0)

      return dom
    })
  }, [])

  const renderPrimaryCategories = useCallback(() => {
    return primaryCategories.map((item, i) => (
      <Fragment key={`primary-category-item-${item.slug}`}>
        <Link prefetch={false} href={`/category/${item.slug}`}>
          <a className="py-2 py-md-0">{`${item.name} Competitions`}</a>
        </Link>{' '}
        {i + 1 !== primaryCategories.length && <span className="mx-2">|</span>}
      </Fragment>
    ))
  }, [primaryCategories])

  return (
    <footer className="copyright-area">
      <div className="container">
        <div className="mb-4 row">
          <section className="mb-5 col-12 col-md-4 col-lg-3">
            <h5>Keep in Touch</h5>

            <div className="flex-wrap d-flex social-links">
              <a
                href="https://www.youtube.com/channel/UCdqJoP6_7pXuid2xr-Hz3Yg"
                target="_blank"
                rel="noopener noreferrer"
                className="mb-3"
              >
                <span className="icon">
                  <i className="fa fa-youtube" />
                </span>

                <span className="sr-only">YouTube</span>
              </a>

              <a
                href="https://twitter.com/elitecompltd"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="icon">
                  <i className="fa fa-twitter" />
                </span>

                <span className="sr-only">Twitter</span>
              </a>

              <a
                href="https://www.facebook.com/elitecompltd/"
                target="_blank"
                className="mb-3"
                rel="noopener noreferrer"
              >
                <span className="icon">
                  <i className="fa fa-facebook" />
                </span>

                <span className="sr-only">Facebook</span>
              </a>

              <a
                href="https://www.instagram.com/elitecompetitionsltd"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="icon">
                  <i className="fa fa-instagram" />
                </span>

                <span className="sr-only">Instagram</span>
              </a>

              <a
                href="https://www.tiktok.com/@elite_competitions"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="icon d-flex justify-content-center align-items-center">
                  <Image
                    src={getLocalAssetFromCDN('/assets/img/logo-tiktok.svg')}
                    alt="Tiktok"
                    className="d-inline"
                    loading="lazy"
                    width={20}
                    height={20}
                  />
                </span>
              </a>
            </div>
          </section>

          <section className="mb-5 col-12 col-md-4 col-lg-3">
            <h5>Payment Methods</h5>

            <Stack direction="column" gap="8px">
              <Stack direction="row" gap="8px">
                <StyledVisaIcon viewBox="0 0 34 24" />

                <StyledMastercardIcon viewBox="0 0 35 24" />

                <StyledAmexIcon viewBox="0 0 35 24" />
              </Stack>

              <Stack direction="row" gap="8px">
                <StyledApplePayIcon viewBox="5 3.5 35 24" />

                <StyledGooglePayIcon viewBox="-1 -5 35 24" />

                <StyledPayPalIcon viewBox="2 -2 30 35" />
              </Stack>
            </Stack>
          </section>

          <section className="mb-5 col-12 col-md-4 col-lg-3">
            <h5>Contact Us</h5>

            <ul className="footer-list">
              <li>
                <a
                  href="mailto:info@elitecompetitions.co.uk"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="py-2 py-md-0"
                >
                  info@elitecompetitions.co.uk
                </a>
              </li>

              <li className="mb-3">
                <a
                  href="tel:01253 836633"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="py-2 py-md-0"
                >
                  01253 836633
                </a>
              </li>
            </ul>
          </section>

          <section className="mb-5 col-12 col-md-4 col-lg-3">
            <h5>Useful Links</h5>

            <div>
              <Link prefetch={false} href="/faqs">
                <a className="py-2 py-md-0">FAQs</a>
              </Link>

              <span className="mx-2">| </span>

              <Link prefetch={false} href="/elite-competitions-app">
                <a className="py-2 py-md-0">Download Elite Competitions App</a>
              </Link>

              <span className="mx-2">| </span>

              <Link prefetch={false} href="/tnc">
                <a className="py-2 py-md-0">Terms &amp; Conditions</a>
              </Link>

              <span className="mx-2">| </span>

              <Link prefetch={false} href="/privacy-policy">
                <a className="py-2 py-md-0">Privacy Policy</a>
              </Link>

              <span className="mx-2">| </span>

              <Link prefetch={false} href="/cookie-policy">
                <a className="py-2 py-md-0">Cookie Policy</a>
              </Link>

              <span className="mx-2">| </span>

              <Link prefetch={false} href="/sitemap">
                <a className="py-2 py-md-0">Sitemap</a>
              </Link>

              <span className="mx-2">| </span>

              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.gamcare.org.uk/"
                className="py-2 py-md-0"
              >
                GamCare
                <span className="mx-2">| </span>
              </a>

              <Link prefetch={false} href="/">
                <a className="py-2 py-md-0">Online Competitions</a>
              </Link>
            </div>
          </section>

          <section className="mb-5 col-12">
            {isWEB() && (
              <div>
                <h5>Download the App on</h5>

                <div className="flex-wrap d-flex">
                  <div className="mb-3 mr-2">
                    <a
                      className="btn download-app p-1"
                      href={config.externalLinks.playStoreUrl}
                      target="_blank"
                    >
                      <div className="d-flex download-gap ">
                        <div className="d-flex flex-column">
                          <i className="ml-1 mt-1 fa fa-android download-app-icon-size"></i>
                        </div>

                        <div className="font-wrap-download-heading">
                          <span className="font-heading-download">
                            Download Elite
                          </span>
                          Android App
                        </div>
                      </div>
                    </a>
                  </div>

                  <div className="mb-3 mr-2">
                    <a href={config.externalLinks.appStoreUrl} target="_blank">
                      <Image
                        src={getLocalAssetFromCDN(
                          '/assets/img/apple-store-badge.svg'
                        )}
                        width={120}
                        height={35}
                        loading="lazy"
                        alt="Apple Store"
                      />
                    </a>
                  </div>
                </div>
              </div>
            )}
          </section>

          <section className="mb-5 col-12">
            <h5 className="text-left">Active Competitions</h5>
            <div>{renderActiveCompetitions()}</div>
          </section>

          <section className="mb-5 col-12">
            <h5 className="text-left">Competition Categories</h5>
            <div>{renderPrimaryCategories()}</div>
          </section>

          <section className="mb-3 col-12 mb-md-0">
            <h5 className="text-left">Winner&apos;s Podium</h5>
            <div>{renderWinnersPodium()}</div>
          </section>
        </div>
      </div>

      <div className="copyright-row">
        <div className="mt-4 text-center col-md-12">
          Copyright © {date.getFullYear()}{' '}
          <Link prefetch={false} href="/">
            <a>Elite Competitions</a>
          </Link>
          <span className="px-1 py-2 ml-2 footer-age-icon">18+</span>
        </div>
      </div>
    </footer>
  )
}

export default Footer
